import { Injectable } from '@angular/core';
import { ApiService } from '@Services/api.service';
import { ISearchResponse2 } from '@Shared/models/index.model';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { IUSDeletionPayload, IUSSearchObj, IUSSearchResponse } from './user-session.model';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private baseURL = environment.apiBaseUrl + '/rest/users/log/';
  constructor(private apiService: ApiService) {} 
  clear = (uas: IUSDeletionPayload[]) => {
    return this.apiService.deleteWithBody(`${this.baseURL}clear`, uas);
  };
  search = (query?: IUSSearchObj) => { 
    return this.apiService.get<ISearchResponse2<IUSSearchResponse>>(
      `${this.baseURL}search`,
      query
    );
  };
}
