import { Pipe, PipeTransform } from '@angular/core';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
import { EPageBtnID } from '../models/IMenuItem';
function formatResp(value: boolean) {
  return { enabled: value };
}
@Pipe({
  name: 'pmV',
  standalone: true,
})
export class PermissionManagerViewPipe implements PipeTransform {
  transform(v: EPageBtnID | string) {
    if (!PermissionManager.pagePermission) return formatResp(true);
    const res = PermissionManager.selectFromSimpleMap(v);
    if (!res) return formatResp(true);
    if (!res.viewAccess) {
      return formatResp(false);
    }
    return formatResp(true);
  }
}

@Pipe({
  name: 'pmE',
  standalone: true,
})
export class PermissionManagerEditPipe implements PipeTransform {
  transform(v: EPageBtnID | string) {
    if (!PermissionManager.pagePermission) return formatResp(true);
    const res = PermissionManager.selectFromSimpleMap(v);
    if (!res) return formatResp(true);
    if (!res.editAccess) {
      return formatResp(false);
    }
    return formatResp(true);
  }
}

@Pipe({
  name: 'pm',
  standalone: true,
})
export class PermissionManagerPipe implements PipeTransform {
  transform(v?: EPageBtnID | string) {
    if (!PermissionManager.pagePermission) return this.formatResp();
    if (v) return this.formatResp(PermissionManager.selectFromSimpleMap(v));
    else return this.formatResp(PermissionManager.pagePermission);
  }
  formatResp(menu?: { editAccess?: boolean; viewAccess?: boolean }) {
    if (!menu) return { editAccess: true, viewAccess: true };
    return { editAccess: menu.editAccess, viewAccess: menu.viewAccess };
  }
}

export const PermissionManagerPipesModule = [
  PermissionManagerEditPipe,
  PermissionManagerPipe,
  PermissionManagerViewPipe,
];
export default PermissionManagerPipesModule;
