import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { environment } from '@environments/environment';
import { ICompanyConfig } from './config';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { Store } from '@ngrx/store';
import { UserStateStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/user-state/user-state.reducer';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private retryCount: number = 2;

  constructor(
    private apiService: ApiService,
    public store: Store,
  ) {}

  fetchConfigByCompanyCode(companyCode: string) {
    return this.apiService
      .get<ICompanyConfig>(environment.apiBaseUrl + '/rest/config/company/' + companyCode)
      .pipe(retry(this.retryCount));
  }

  deleteLanguage = (id: number) => {
    return this.apiService
      .delete(environment.apiBaseUrl + '/rest/config/language/' + id)
      .pipe(retry(this.retryCount));
  };
  deleteCurrency = (id: number) => {
    return this.apiService
      .delete(environment.apiBaseUrl + '/rest/config/currency/' + id)
      .pipe(retry(this.retryCount));
  };

  checkCompanyConfigForUpdate(companyCode: string) {
    if (companyCode == environment.userProfile?.users?.primaryCompany)
      this.store.dispatch(UserStateStore.actions.companyConfig.getFromOnline({ companyCode: companyCode }));
  }
  saveConfig(config: ICompanyConfig[]) {
    return this.apiService
      .postWithEndorsment<ICompanyConfig[]>(`${environment.apiBaseUrl}/rest/config/`, config)
      .pipe(tap((r) => this.checkCompanyConfigForUpdate(config[0]?.config?.code)));
  }
}
