import { ITranslationGlossaryResponse } from './translation-glossary.response.model';

export interface ITransGlossaryEntry {
  currentText?: string;
  sourceText: string;
  targetText: string;
}

export interface ITranslationGlossary {
  companyCode?: string;
  entries: ITransGlossaryEntry[];
  name: string;
  sourceLang: string;
  targetLang: string;
  code?: string;
}
export class TranslationGlossary implements ITranslationGlossary {
  companyCode?: string;
  entries: ITransGlossaryEntry[] = [];
  name: string;
  sourceLang: string;
  targetLang: string;
  code?: string;
  constructor(transInterface?: ITranslationGlossaryResponse) {
    Object.assign(this, transInterface);
    // debugger;
    this.entries = transInterface.entries
      .split('\n')
      .filter((x) => x)
      .map((kvp) => ({
        sourceText: kvp.split('\t')[0],
        targetText: kvp.split('\t')[1],
      }));
  }
}
