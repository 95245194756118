import { Injectable, computed, effect, signal } from '@angular/core';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdlerComponent } from './idler.component';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import {
  ReplaySubject,
  Subject,
  Subscription,
  distinctUntilChanged,
  filter,
  first,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Injectable()
export class IdlerService {
  protected readonly idleState = signal<string>(null);
  protected onIdleFunctions: (() => any)[] = [];
  protected readonly _timeLeft = signal<number>(null);
  idlerModal: MatDialogRef<IdlerComponent>;
  readonly timeLeft = computed(() => this._timeLeft());
  protected readonly allowedToWatch$ = new ReplaySubject<boolean>(1);
  protected readonly useCompanyTimeoutSeconds$ = this.authS.companyConfig$.pipe(
    map((cc) => (cc?.config?.timeOutAfter || 0) * 60),
  );
  constructor(
    protected idle: Idle,
    public dialog: MatDialog,
    public authS: AuthenticationService,
  ) {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.allowedToWatch$
      .pipe(
        switchMap((allowedToWatch) =>
          this.useCompanyTimeoutSeconds$.pipe(
            distinctUntilChanged(),
            map((useCompanyTimeout) => ({ useCompanyTimeout, allowedToWatch })),
          ),
        ),
        switchMap(({ allowedToWatch, useCompanyTimeout }) =>
          allowedToWatch && useCompanyTimeout
            ? merge(
                of(this.start(useCompanyTimeout)),
                this.idle.onIdleStart.pipe(
                  tap(() => {
                    this.idleState.set("You've gone idle!");
                    this.openTimeWarningModal();
                  }),
                ),
                this.idle.onTimeoutWarning.pipe(
                  tap((countdown) => {
                    this._timeLeft.set(countdown);
                  }),
                ),
                this.idle.onIdleEnd.pipe(
                  tap(() => {
                    this.idlerModal.close();
                    this.idleState.set("You've left the state of idleness!");
                  }),
                ),
                this.idle.onTimeout.pipe(
                  tap(() => {
                    this.idleState.set('Timed out!');
                    for (const func of this.onIdleFunctions) func();
                  }),
                ),
              )
            : of(null).pipe(tap(() => this.idle.stop())),
        ),
      )
      .subscribe();

    // .pipe(
    //     switchMap(() => this.allowedToWatch$.pipe(first())),
    //     filter((allowedToWatch) => allowedToWatch == true),
    //   )
    effect(() => {
      console.log('Idle State', this.idleState());
    });
  }
  dispatchStart() {
    this.allowedToWatch$.next(true);
  }
  dispatchStop() {
    this.allowedToWatch$.next(false);
  }
  addOnIdleFunction(func: () => any) {
    this.onIdleFunctions.push(func);
  }

  protected start(idleTimeSeconds: number, timeoutSeconds: number = (idleTimeSeconds || 0) * 0.2) {
    // debugger;
    console.log('idleTimeSeconds', idleTimeSeconds, 'timeoutSeconds', timeoutSeconds);
    this.idle.setIdle(idleTimeSeconds);
    this.idle.setTimeout(timeoutSeconds);

    this.idle.watch();
  }
  protected clear() {
    this.onIdleFunctions = [];
  }
  protected openTimeWarningModal() {
    this.idlerModal = this.dialog.open(IdlerComponent, {
      backdropClass: 'idler-container',
      // height: 'auto',
      // width: 'auto',
    });
  }
}
