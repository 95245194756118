import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import {
  IUserMenu,
  IUserMenuConfigMap,
  IUserMenuConfigSimpleIDMap,
} from '@User/usermenu/usermenu-extras/usermenu.interface';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
import { UserGroup } from '@Shared/models/life/user/Users';
import { environment } from '@environments/environment';
import { ICompanyConfig } from 'projects/evolutics-client-ui/src/app/Life/admin/configuration/config';
import { ICompany } from 'projects/evolutics-client-ui/src/app/Life/Setup/organization/companies/create-company/create-company.model';

type IICompany=ICompany['companyInfo']
export namespace UserStateStore {
  //#region STORE

  export interface IState {
    userGroup?: UserGroup;
    companyConfig?: ICompanyConfig;
    company?: IICompany;
  }

  const initialState: IState = {};
  //#endregion

  //#region ACTIONS
  export const actions = {
    userGroup: createActionGroup({
      source: 'User Group API',
      events: {
        setItem: props<{ item: UserGroup }>(),
        // getFromOnline: props<{ userGroupCode: string }>(),
        getFromOnlineSuccess: props<{ item: UserGroup }>(),
        end: emptyProps(),
      },
    }),
    companyConfig: createActionGroup({
      source: 'Company Config API',
      events: {
        setItem: props<{ item: ICompanyConfig }>(),
        getFromOnline: props<{ companyCode?: string }>(),
        getFromOnlineSuccess: props<{ item: ICompanyConfig }>(),
        end: emptyProps(),
      },
    }),
    company: createActionGroup({
      source: 'Company API',
      events: {
        setItem: props<{ item: IICompany }>(),
        getFromOnline: props<{ companyCode?: string }>(),
        getFromOnlineSuccess: props<{ item: IICompany }>(),
        end: emptyProps(),
      },
    }),
  };
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const select = (state: IAppState) => state.userState;
    // export const usermenu = createSelector(select, (state) => state.userMenu);
    export const companyConfig = createSelector(select, (state) => state.companyConfig);
    export const company = createSelector(select, (state) => state.company);
    // export const userMenuConfigMap = createSelector(select, (state) => state.userMenuConfigMap);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.userGroup.setItem, (state, { item }) => {
      environment.userGroup = item;
      return { ...state, userGroup: item };
    }),
    on(actions.companyConfig.setItem, (state, { item }) => {
      environment.userCompanyConfig = item;
      return { ...state, companyConfig: item };
    }),
    on(actions.company.setItem, (state, { item }) => {
      return { ...state, company: item };
    }),
  );
  //#endregion
}
