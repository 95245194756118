import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ITextCase2,
  LoaderComponent,
  LoaderService,
  ModalComponent,
  ModalComponents,
  TextCase2Component,
} from 'ets-fe-ng-sdk';
import { ReplaySubject, of, switchMap, tap } from 'rxjs';
import { IUserDetail, IUserSearchObj } from '@User/adminuser/user-extras/user.interface';
import { UserPreviewService } from './user-preview.service';
import { DialogPosition } from '@angular/material/dialog';

@Component({
  selector: 'user-preview-modal',
  standalone: true,
  imports: [CommonModule, ModalComponents, LoaderComponent, TextCase2Component],
  templateUrl: './user-preview-modal.component.html',
  styleUrl: './user-preview-modal.component.scss',
  providers: [LoaderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreviewModalComponent {
  readonly modalRef = viewChild(ModalComponent);

  readonly query$ = new ReplaySubject<IUserSearchObj>(1);
  readonly query = toSignal(this.query$);
  readonly user = toSignal(
    this.query$.pipe(
      tap(() => this.loader.restart()),
      switchMap((query) => (query ? this.userS.getUser(query.userName) : of(null))),
      tap(() => this.loader.stop()),
    ),
  );
  readonly loading = computed<boolean>(() => this.loader.isLoading());
  readonly labels = computed(() => {
    const user = this.user();
    return this.userS.labels()?.map<ITextCase2<IUserDetail>>((l) => ({ ...l, value: user[l.name] }));
  });
  private readonly _height = 180;
  readonly height = `${this._height}px`;
  private readonly _width = 300;
  readonly width = `${this._width}px`;

  constructor(
    public userS: UserPreviewService,
    public loader: LoaderService,
  ) {}

  open = (event: PointerEvent, query: Pick<IUserSearchObj, 'userName'>) => {
    if (!query || !Object.values(query).length || !query.userName) return;
    this.query$.next(query);
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const cursorY = event.clientY;
    const cursorX = event.clientX;

    const position: DialogPosition = {
      top: cursorY + this._height > windowHeight ? null : `${cursorY}px`,
      left: cursorX + this._width > windowWidth ? null : `${cursorX}px`,
    };

    this.modalRef().open({
      // pointerEvent: event,
      matDialogConfig: {
        position: {
          top: position.top,
          bottom: position.top ? null : `0px`,
          left: position.left,
          right: position.left ? null : `0px`,
        },
        panelClass: 'user-preview',
        backdropClass: 'opacity-0',
      },
    });
  };

  close() {
    this.modalRef()?.close();
  }
}
