import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { NotificationsService } from './notifications.service';
// import { SharedModule } from '@Shared/shared.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
    imports: [  NotificationsComponent],
    providers: [NotificationsService,NotificationsComponent],
})
export class NotificationsModule {}
