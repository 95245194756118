import { MenuItem } from "@Shared/models/IMenuItem";

export interface ISearchResponse { 
  menuItems: MenuItem[];
  type: ESearchType; 
}

export enum ESearchType {
  main = 'Services',
  help = 'Help',
}

