import { Injectable, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EVFunctions, ITextCase2, KeyOfType } from 'ets-fe-ng-sdk';
import { of, tap } from 'rxjs';
import { CompanyService } from '@Services/company.service';
import { UtilityService } from '@Services/utility.service';
import { User } from '@Shared/models/life/user/Users';
import { IUserDetail, IUserSearchObj } from '@User/adminuser/user-extras/user.interface';
import { UsersService } from '@User/adminuser/user-extras/users.service';

@Injectable({
  providedIn: 'root',
})
export class UserPreviewService { 
  readonly cache = this.uS.createCacheManager<IUserDetail, string>({
    maxSize: 20,
  });
  readonly userGroups = toSignal(this.userS.getusersGroup());
  readonly companies = toSignal(this.compS.getCompanyCodeAndDesc());
  readonly userGroupsMap = computed(() => this.userGroups()?.toMap('code') || {});
  readonly companiesMap = computed(() => this.companies()?.toMap('code') || {});
  readonly labels = computed<ITextCase2<IUserDetail>[]>(() => [
    { label: 'Name', name: 'fullName' },
    { label: 'Email', name: 'email', isEmail: true },
    {
      label: 'Group',
      name: 'group',
      formatter: (v) => EVFunctions.strConcatenator2(v, this.userGroupsMap()[v]?.description),
    },
    {
      label: 'Company',
      name: 'primaryCompany',
      formatter: (v) => EVFunctions.strConcatenator2(v, this.companiesMap()[v]?.description),
    },
  ]);
  constructor(
    public uS: UtilityService,
    public userS: UsersService,
    public compS: CompanyService,
  ) {}

  getUser = (userName: string) => {
    const cachedItem = this.cache.get(userName);
    return cachedItem
      ? of(cachedItem)
      : this.userS.getUserByUsernameLite(userName).pipe(tap((user) => this.cache.save(userName, user)));
  };
}
