import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AppService } from '@Services/app.service';
import { AppLocalCacheService } from '@Services/cache/local-cache.service';
import { environment } from '../../../environments/environment';
import { IUserMenuConfigDetail } from '../../User/usermenu/usermenu-extras/usermenu.interface';
import { MenuItem } from '../models/IMenuItem';
import { PermissionManagerPipesModule } from './permission-manager.pipe';
import {
	FormInvalidClassPipe,
	PaginatorPipe,
	ArraySplitter,
	CodeTitleDescPipe,
	CustomDatePipe,
	FieldToLabelPipe,
	FilterArrayByStringPipe,
	FilterArrayPipe,
	FilterFormArrayGroupPipe,
	FormSchemaToTableColumnsPipe,
	FormValuePipe,
	FunctionCaller,
	FunctionCaller1,
	FunctionCaller2,
	FunctionCaller3,
	GenderPipe,
	GetValueLabel,
	HasFormValuePipe,
	HasValuePipe,
	ObjectToLabelsPipe,
	HttpListCaller,
	HttpListCaller1,
	HttpListCaller2,
	IsClonePage,
	IsShowPage,
	ObjectToArrayPipe,
	RefresherPipe,
	RemoveUsedOptionsPipe,
	ReplaceAllPipe,
	ResponsiveValPipe,
	RoundPipe,
	RemoveUsedOptionsReactivePipe,
	SecondsToTimePipe,
	SortPipe,
	TyperPipe,
	StrConcatenatorPipe,
	ToAnyArrayPipe,
	ToAnyPipe,
	TrimPipe,
	TrimTextPipe,
	ValueFormatterPipe,
	ValueOrXPipe,
	XOrYPipe,
} from 'ets-fe-ng-sdk';
 
// English.
import { TranslatePipe } from './translate.pipe';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
import { UsermenuService } from '@User/usermenu/usermenu-extras/usermenu.service';

@Pipe({
	name: 'filterMenuArray',
	standalone: true,
})
export class FilterMenuArrayPipe implements PipeTransform {
	transform(arr: MenuItem[], parent?: MenuItem) {
		// if(parent)
		// debugger
		return parent
			? this.cacheS.getAndSet('menuItem_' + parent.id, Promise.resolve(this.fetcher(arr)))
			: Promise.resolve(this.fetcher(arr));
	}
	fetcher = (arr: MenuItem[]) => {
		if (!arr) {
			return <MenuItem[]>[];
		}
		return arr.filter((x) => !x.hidden && this.imePipe.transform(x)?.enabled);
	};
	constructor(
		public imePipe: IsNavMenuEnabledPipe,
		public appS: AppService,
		public cacheS: AppLocalCacheService,
	) {}
}

/**Only use it for navigation menu items */
@Pipe({
	name: 'isMenuEnabled',
	standalone: true,
})
export class IsNavMenuEnabledPipe implements PipeTransform {
	transform(
		menu: MenuItem,
		userMenu: IUserMenuConfigDetail[] = this.umS.userMenu()?.userMenuConfigDetails,
	) {
		// return { enabled: !!menu?.viewAccess };
		// if (menu.system == ESystem.general) {
		//   console.log(this.authS.usermenuConfigMap);
		//   debugger;
		// }
		if (environment.useUserMenu)
			return { enabled: !!PermissionManager.bUsermenuConfigSimpleIDMap?.[menu.id]?.viewAccess };
		else return { enabled: true };
	}
	constructor(public umS:UsermenuService) {}
}

const comps = [
	ArraySplitter,
	CustomDatePipe,
	FieldToLabelPipe,
	FilterArrayByStringPipe,
	FilterArrayPipe,
	FilterFormArrayGroupPipe,
	FilterMenuArrayPipe,
	FormInvalidClassPipe,
	FormSchemaToTableColumnsPipe,
	FormValuePipe,
	FunctionCaller,
	FunctionCaller1,
	RemoveUsedOptionsReactivePipe,
	FunctionCaller2,
	FunctionCaller3,
	GenderPipe,
	GetValueLabel,
	HasFormValuePipe,
	HasValuePipe,
	CodeTitleDescPipe,
	HttpListCaller,
	HttpListCaller1,
	HttpListCaller2,
	IsClonePage,
	ObjectToLabelsPipe,
	IsNavMenuEnabledPipe,
	IsShowPage,
	ObjectToArrayPipe,
	RefresherPipe,
	RemoveUsedOptionsPipe,
	ReplaceAllPipe,
	ResponsiveValPipe,
	RoundPipe,
	SecondsToTimePipe,
	TyperPipe,
	SortPipe,
	StrConcatenatorPipe,
	ToAnyArrayPipe,
	PaginatorPipe,
	ToAnyPipe,
	TranslatePipe,
	TrimPipe,
	TrimTextPipe,
	ValueFormatterPipe,
	ValueOrXPipe,
	XOrYPipe,
	...PermissionManagerPipesModule,
];
const modules = [];
@NgModule({
	imports: [CommonModule, ...modules, ...comps],
	exports: [...comps, ...modules],
	providers: [IsNavMenuEnabledPipe],
})
export class UtilityPipesModule {}
