import { Injectable } from '@angular/core';
import { AppService } from '@Services/app.service';
import { StorageService } from '@Services/storage.service';
import { UtilityService } from '@Services/utility.service';
import { ESystem } from '@Shared/models/index.model';
import { IMenuItem, MenuItem } from '@Shared/models/IMenuItem';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ESearchType, ISearchResponse } from './search.interface';
import { BehaviorSubject } from 'rxjs';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  menuIndex: MenuItem[];
  private readonly storageKey = 'searchIndex';
  systemMenuIndex: MenuItem[];
  rebuiltIndex = new BehaviorSubject<MenuItem[]>([]);
  constructor(public appS: AppService, public uS: UtilityService, public sS: StorageService) {
    this.sS.getItemA(this.storageKey).then((r) => {
      this.menuIndex = r;
      this.setMenuLinkToIdIndex(this.menuIndex);
    });
  }

  async search(query: string) {
    return new Promise<
      {
        menuItems: MenuItem[];
        type: ESearchType;
      }[]
    >((res) => {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(new URL('./search.worker', import.meta.url));
        worker.onmessage = ({ data }) => {
          console.log('data', data);
          res([
            { menuItems: data[0], type: ESearchType.main },
            { menuItems: data[1], type: ESearchType.help },
          ]);
        };
        worker.postMessage({ query, menuIndex: this.systemMenuIndex });
      } else {
        query = query?.toLowerCase()?.trim();
        if (!query) return null;
        const queries = this.parseQuery1(query);
        if (queries.length > 1) queries.unshift(query);
        // console.log('queries', queries);
        Promise.all([this.searchPages(queries), this.searchHelp(queries)]).then((r) =>
          res([
            { menuItems: r[0], type: ESearchType.main },
            { menuItems: r[1], type: ESearchType.help },
          ])
        );
      }
    });
  }

  parseQuery1(query: string) {
    return query?.split(' ')?.filter((q) => q);
  }

  async searchPages(queries: string[]) {
    const res: MenuItem[] = [];
    for (const query of queries) {
      res.push(...(this.systemMenuIndex?.filter((x) => x.breadcrumbsStr.startsWith(query)) || []));
    }
    for (const query of queries) {
      res.push(...(this.systemMenuIndex?.filter((x) => x.breadcrumbsStr.includes(query)) || []));
    }
    for (const query of queries) {
      res.push(...(this.systemMenuIndex?.filter((x) => x.labelLowerCase.startsWith(query)) || []));
    }
    for (const query of queries) {
      res.push(...(this.systemMenuIndex?.filter((x) => x.labelLowerCase.includes(query)) || []));
    }

    return [...new Set(res)];
  }

  async searchHelp(queries: string[]) {
    return [];
  }
  setSystemMenuIndex(system: ESystem) {
    this.systemMenuIndex = this.menuIndex?.filter((x) => x.system == system);
  }
  private indexMenuItems = async (menuItems: MenuItem[]) => {
    const index: MenuItem[] = [];
    const recurser = (subMenuItems: MenuItem[], parent?: MenuItem) => {
      if (!subMenuItems) return;
      for (const menuItem of subMenuItems) {
        if (!menuItem || menuItem.isDivider || !menuItem.label) continue;
        else {
          if (parent) {
            menuItem.breadcrumbs = [...(parent.breadcrumbs || [])];
            menuItem.breadcrumbs.push({
              key: parent.label,
              value: parent.link,
            });
          }
          menuItem.labelLowerCase = menuItem.label.toLowerCase();
          menuItem.link = menuItem.link?.trim()?.toLowerCase();
          menuItem.system = menuItem.system || parent?.system;
          menuItem.systemIcon = menuItem.systemIcon || parent?.systemIcon || menuItem.icon;
          if (menuItem.hasSub) {
            if (menuItem.link?.trim()) index.push(menuItem);
            recurser(menuItem.subs, menuItem);
          } else if (!menuItem.link?.trim()) continue;
          else {
            menuItem.breadcrumbsStr = (
              menuItem.breadcrumbs?.map((x) => x.key)?.join(' ') || ''
            ).toLowerCase();
            index.push(menuItem);
          }
        }
      }
    };
    recurser(menuItems);
    // console.table(index);
    index.sort((x, y) => x.label.localeCompare(y.label));
    this.setMenuLinkToIdIndex(index);
    return index;
  };
  setMenuLinkToIdIndex(index: MenuItem[]) {
    const res = {};
    index?.forEach((r) => (res[r?.link?.toLowerCase()] = r.id));
    PermissionManager.menuLinkToIDIndex = res;
  }
  async buildIndex() {
    this.menuIndex = this.sS.getItem(this.storageKey);
    // if (this.menuIndex) return;
    // debugger;
    if (typeof Worker !== 'undefined' && true) {
      const worker = new Worker(new URL('./build-search-index.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
        this.menuIndex = data.index;
        PermissionManager.menuLinkToIDIndex = data.menuLinkToIDIndex;
        this.saveToLocal();
        this.rebuiltIndex.next(this.menuIndex);
        worker.terminate();
      };
      worker.postMessage(this.appS.activeSystems);
    } else {
      this.indexMenuItems(this.appS.activeSystems).then((r) => {
        this.menuIndex = r;
        this.saveToLocal();
        this.rebuiltIndex.next(this.menuIndex);
      });
    }
  }
  saveToLocal() {
    this.sS.saveItemA(this.storageKey, this.menuIndex);
  }
}
