import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { AppSaverService } from '@Services/cache/saver.service';
import { StorageService } from '@Services/storage.service';
import { TranslationService } from '@Services/translation.service';
import { environment } from '@environments/environment';
import { ITransGlossaryEntry, TranslationGlossary } from './translation-glossary.request.model';
import { ITranslationGlossaryResponse } from './translation-glossary.response.model';
import { lastValueFrom } from 'rxjs';
import { SDKEnvironment } from 'ets-fe-ng-sdk/lib/Shared/models/environment.model';
import { environment as SDKEvt } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class TranslationGlossaryService extends AppSaverService {
  private baseURL = environment.apiBaseUrl + '/rest/translation/glossary/';
  companyCode = 'company';
  name = 'glossary';
  glossary: TranslationGlossary;
  constructor(
    private apiService: ApiService,
    public transService: TranslationService,
    public storageS: StorageService,
  ) {
    super('glossary', environment.transGlossaryKey);
    // debugger
    environment.transGlossary = this.glossary;
  }
  async submitGlossary(entries: ITransGlossaryEntry[]) {
    const data: TranslationGlossary = {
      entries: await Promise.all(entries.map((x) => this.translateGlossaryEntry(x))),
      companyCode: this.companyCode,
      // companyCode: environment.userProfile?.users?.primaryCompany,
      name: this.name,
      sourceLang: environment.sourceLanguage,
      targetLang: environment.targetLanguage,
    };
    // debugger;
    await this.deleteGlossary(environment.transGlossary?.code).toPromise();
    return lastValueFrom(
      this.apiService.post<ITranslationGlossaryResponse>(this.baseURL, data).pipe(
        map((r) => new TranslationGlossary(r)),
        tap((r) => (environment.transGlossary = r)),
      ),
    );
  }
  private translateGlossaryEntry(entry: ITransGlossaryEntry) {
    return new Promise<ITransGlossaryEntry>((resolve) => {
      if (entry?.sourceText) resolve({ sourceText: entry.sourceText, targetText: entry.targetText });
      else {
        this.transService
          .translateOld(entry.currentText)
          .toPromise()
          .then((r) => {
            entry.sourceText = r;
            resolve({
              sourceText: entry.sourceText,
              targetText: entry.targetText,
            });
          });
      }
    });
  }
  getGlossary() {
    return this.apiService.get<ITranslationGlossaryResponse[]>(`${this.baseURL}`).pipe(
      map((r) => {
        SDKEvt._targetLanguage = environment.targetLanguage;
        const glossary = r?.find(
          (x) =>
            x.sourceLang == environment.sourceLanguage &&
            x.targetLang == environment.targetLanguage &&
            x.name == this.name,
        );
        return glossary ? new TranslationGlossary(glossary) : null;
      }),
      map(async (glossary) => {
        if (!glossary) return null;
        await Promise.all(
          glossary.entries.map((x) => {
            return lastValueFrom(this.transService.translateOld(x.sourceText)).then(
              (trans) => (x.currentText = trans),
            );
          }),
        );
        return glossary;
      }),
      tap(async (r) => {
        this.glossary = environment.transGlossary = await r;
        this.saveToLocal();
      }),
    );
  }
  deleteGlossary(code: string) {
    return this.apiService.delete(`${this.baseURL}${code}`);
  }
}
