import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateUser, UserGroup } from '@Shared/models/life/user/Users';
import { lastValueFrom, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';

import { ApiService } from '@Services/api.service';
import { StorageService } from '@Services/storage.service';
import { UserService } from '@Services/user.service';
import { environment } from '../../../environments/environment';
import { ERole, IUser } from './authentication.interface';
import { ELanguage } from '@Shared/models/index.model';
import { SearchService } from '@Shared/components/search/search-extras/search.service';
import { TranslationGlossaryService } from 'projects/evolutics-client-ui/src/app/Life/Setup/translation/translation-extras/translation-glossary.service';
import { UserSessionService } from '@User/user-session/us-extras/user-session.service';
import { FindWorkflowService } from 'projects/evolutics-client-ui/src/app/Life/Workflow/service/find-workflow.service';
import { Store } from '@ngrx/store';
import { AuthStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/auth/auth.reducer';
import { TranslationGlossaryStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/translation/translation.reducer';
import { ICompanyConfig } from 'projects/evolutics-client-ui/src/app/Life/admin/configuration/config';
import { ConfigService } from 'projects/evolutics-client-ui/src/app/Life/admin/configuration/config.service';
import { UserMenuStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/usermenu/usermenu.reducer';
import { LabelsStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/labels/labels.reducer';
import { UserStateStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/user-state/user-state.reducer';
import { ICompany } from 'projects/evolutics-client-ui/src/app/Life/Setup/organization/companies/create-company/create-company.model';

import { ErrorHandlerOptions } from '@sentry/angular';
import { ErrorReporterService } from 'ets-fe-ng-sdk';
import { IJupiterCompany } from 'projects/evolutics-admin-ui/src/app/shared/models/admin-company.interface';
import { CompanyService } from 'projects/evolutics-client-ui/src/app/Services/life/company.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  baseURL = environment.apiBaseUrl + '/rest/authentication/';
  private readonly countKey = 'countKey';
  private readonly timerKey = 'authTimer';
  public get count() {
    return this.sS.getItem<number>(this.countKey);
  }
  public set count(value) {
    this.sS.saveItem(this.countKey, value);
  }
  readonly waitTime = 0.3; //minutes
  timeLeft = 0; //minutes
  readonly maxCount = 9;
  private readonly testUserMenuCode = null;
  readonly userProfile = this.store.selectSignal(AuthStore.selectors.userProfile);
  readonly userProfile$ = this.store.select(AuthStore.selectors.userProfile);
  readonly companyConfig$ = this.store.select(UserStateStore.selectors.companyConfig);
  // readonly company$ = this.store.select(UserStateStore.selectors.company);
  // private readonly testUserMenuCode = 'UM36';
  constructor(
    public apiService: ApiService,
    public sS: StorageService,
    public errorS: ErrorReporterService,
    public userS: UserService,
    public usS: UserSessionService,
    public transGlossary: TranslationGlossaryService,
    protected configService: ConfigService,
    protected companyService: CompanyService,
    public searchS: SearchService,
    public fwS: FindWorkflowService,
    public store: Store,
  ) {
    const secondsLocked = this.sS.getItem<number>(this.timerKey) || 0; // in seconds
    if (secondsLocked || this.isLocked) {
      this.startTimer(secondsLocked);
    }
    if (!environment.isJupiter) this.getCompanyDetails();
    this.userProfile$.subscribe((user) => {
      this.errorS.setCurrentUser({ email: user?.users?.email, fullName: user?.users?.fullName });
    });
  }
  getCompanyDetails() {
    // debugger;
    this.apiService
      .getWithLocalCache<IJupiterCompany>(
        environment.apiBaseUrl + '/admin/rest/company/code/' + environment.jupiterCompanyCode,
      )
      .subscribe((r) => {
        environment.company = r;
      });
  }
  get isLoggedin() {
    const localUser = this.sS.getItem<IUser>(environment?.userStorageKey);
    if (environment.user?.code && environment.user?.code != localUser?.code) return false;
    return !!localUser;
  }

  async login(value: { userName: string; password: string }) {
    try {
      // debugger;
      return lastValueFrom(
        this.apiService
          .post<{ body: IUser; headers: HttpHeaders }>(this.baseURL + 'login', value, {
            options: {
              withCredentials: environment.useAuthCookies,
              observe: 'response',
            },
          })
          .pipe(
            filter((x) => !!x?.body),
            map((r) => r.body),
          ),
      )
        .then(async (user) => {
          // debugger;
          this.store.dispatch(AuthStore.actions.setAuth({ auth: user }));
          if (user.userRoleType == ERole.superAdmin) {
            this.sS.useSession();
            this.saveUserToLocal();
            return null;
          }
          const profile = await lastValueFrom(this.getProfileFromOnlineByCode(user.code));
          if (!profile) throw 'Profile data not found';
          this.checkUserProfile(profile);
          this.store.dispatch(AuthStore.actions.setUser({ userProfile: profile }));
          // debugger;

          if (profile.users.language && profile.users.language != ELanguage.EN)
            this.store.dispatch(TranslationGlossaryStore.actions.getFromOnline());

          this.saveProfileToLocal(profile).subscribe();
          this.saveUserToLocal();
          this.getAllFromOnline();
          return user;
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    } catch (error) {
      throw error;
    }
  }
  getPreviousLogin = () => {
    return this.usS.search({ sortBy: 'createdOn', pageSize: 2, userCode: environment.user?.code }).pipe(
      map((r) => r?.content?.[1]),
      catchError((e) => of(null)),
    );
  };
  checkUserProfile = (userProfile: CreateUser) => {
    if (userProfile?.users?.status && userProfile?.users?.status != 'A') throw 'Your status is not active';
    if (userProfile?.users?.expiryOn && new Date(userProfile?.users?.expiryOn).getTime() <= Date.now())
      throw 'Your account has expired';
    if (!userProfile?.users?.userMenu) throw `User doesn't have a user menu configuration`;
  };
  getPendingTasksCount = () => {
    return this.fwS
      .searchForWorkflow({
        pageSize: 1,
        pageNumber: 1,
        assignedTo: environment.userName,
        status: 'A',
      })
      .pipe(
        map((r) => r?.total),
        catchError((e) => of(null)),
      );
  };
  getAllFromLocal(alsoFetchOnline = false) {
    this.store.dispatch(AuthStore.actions.getAuthUserL());
  }
  async getAllFromOnline(userProfile?: CreateUser) {
    if (!userProfile) this.store.dispatch(AuthStore.actions.getUserO({}));
    this.store.dispatch(LabelsStore.actions.getFromOnline());
    if (userProfile)
      this.store.dispatch(UserMenuStore.actions.getFromOnline({ usermenuCode: userProfile.users.userMenu }));
    // this.store.dispatch(UserMenuStore.actions.getFromOnline({}));
  }
  get isLocked() {
    return this.maxCount <= this.count;
  }
  recoverPassword(userName: string) {
    return this.apiService.post(this.baseURL + `recover/credentials/${userName}`);
  }

  processForgetPassword(data: { loginUrl: string; passwordAuthUrl: string; username: string }) {
    return this.apiService.post(this.baseURL + `forgot-password/notification`, data);
  }

  /**
   * Calls the endpoint tthat validates the password refrence sent via email
   * @param reference
   * @returns
   */
  validateReference(reference: string) {
    return this.apiService.get<{
      id: number;
      email: string;
      username?: string;
      reference: string;
      passwordAuthUrl: string;
      loginUrl?: any;
      createdOn: string;
      expireOn: string;
      verified?: any;
    }>(`${this.baseURL}validate/reference/${reference}`);
  }

  /**
   * Calls the endpoint that updates a user password.
   * @param reference
   * @param password
   * @returns
   */
  updatePasswordWithReference(data: {
    emailOrUsername: string;
    password: string;
    confirmPassword: string;
    reference: string;
  }) {
    return this.apiService.put(this.baseURL + `forgot-password/${data.reference}`, {
      confirmPassword: data.confirmPassword,
      password: data.password,
      username: data.emailOrUsername,
    });
  }

  startTimer(carryOverSec: number = 0) {
    const time = timer(0, 1000);
    const waitTime = this.waitTime * 60;
    const sub = time
      .pipe(
        map((s) => s + carryOverSec),
        tap((s) => {
          this.sS.saveItem(this.timerKey, s);
          this.timeLeft = waitTime - s;
        }),
        filter((s) => s >= waitTime),
      )
      .subscribe((r) => {
        this.count = 0;
        sub.unsubscribe();
        this.sS.removeItem(this.timerKey);
      });
  }
  //#region user
  getAuthFromLocal = () => this.sS.getItem$<IUser>(environment.userStorageKey);
  getUserFromLocal = () => this.sS.getItem$<CreateUser>(environment.userProfileKey);

  saveUserToLocal() {
    this.sS.saveItem(environment.userStorageKey, environment.user);
  }
  //#endregion

  changePassword(password: string) {
    return this.apiService.put(this.baseURL + `password/${environment.user.code}/${password}`);
  }
  forgotPassword(data: any) {
    return this.apiService.put(this.baseURL + `password${this.apiService.getRequestParse(data)}`);
  }

  async logout(redirectRotue = '/') {
    try {
      await lastValueFrom(
        this.apiService.post<IUser>(this.baseURL + 'logout', null, {
          // options: { withCredentials: true },
        }),
      );
    } catch (error) {
      // debugger;
      console.log(error);
    }
    this.sS.clear();
    location.href = redirectRotue;
    return true;
  }
  get user() {
    return environment.user;
  }
  get token() {
    return environment.token;
  }

  //#region user profile
  private getProfileFromLocal() {
    return this.sS.getItem<CreateUser>(environment.userProfileKey);
  }
  getProfileFromOnlineByCode = (code: string) =>
    this.userS.getUserByCode(code).pipe(mergeMap((r) => this.getProfileFromOnlineByID(r.id)));

  getProfileFromOnlineByID = (id: number) => this.userS.getUserByID(id);

  saveProfileToLocal = (userProfile: CreateUser) =>
    this.sS.saveItem$(environment.userProfileKey, userProfile);

  //#endregion

  //#region userCompanyConfig

  getUserCompanyConfigFromLocal = () => this.sS.getItem$<ICompanyConfig>(environment.userCompanyConfigKey);

  getUserCompanyConfigFromOnline = (companyCode: string) =>
    this.configService.fetchConfigByCompanyCode(companyCode);

  saveUserCompanyConfigToLocal = (companyConfig: ICompanyConfig) =>
    this.sS.saveItem$(environment.userCompanyConfigKey, companyConfig);

  //#endregion

  //#region user company

  getUserCompanyFromLocal = () => this.sS.getItem$<ICompany['companyInfo']>(environment.userCompanyKey);

  getUserCompanyFromOnline = (companyCode: string) =>
    this.companyService.search({ code: companyCode }).pipe(map((r) => r.content?.[0]));

  saveUserCompanyToLocal = (companyConfig: ICompany['companyInfo']) =>
    this.sS.saveItem$(environment.userCompanyKey, companyConfig);

  //#endregion

  //#region user group
  getUserGroupFromLocal = () => this.sS.getItem$<UserGroup>(environment.userGroupStorageKey);

  getUserGroupFromOnline(groupCode: string) {
    return this.userS.getuserUserGroupByGroup(groupCode);
  }
  saveUserGroupToLocal = (userGroup: UserGroup) =>
    this.sS.saveItem$(environment.userGroupStorageKey, userGroup);

  //#endregion
}
