import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { CompanyService as CS } from '@Services/company.service';
import { HttpHeaders } from '@angular/common/http';
import { EndorsementResponse } from '@Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import { ICompany } from 'projects/evolutics-client-ui/src/app/Life/Setup/organization/companies/create-company/create-company.model';
import { IGetQuery, ISearchResponse2 } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends CS {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  createCompany(company: ICompany) {
    return this.apiService.postWithEndorsment<ICompany>(`${this.baseURL}/company/`, company);
  }
  search(query: IGetQuery<{ code: string }>) {
    return this.apiService.get<ISearchResponse2<ICompany['companyInfo']>>(
      `${this.baseURL}/company/search`,
      query,
    );
  }

  saveCompanyNE(company: ICompany) {
    return this.apiService.post<ICompany>(`${this.baseURL}/company/`, company);
  }

  deleteCompanyPayoutMethod = (id: number) => {
    return this.apiService
      .delete(this.baseURL + '/company/payout/method/' + id, {
        options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
      })
      .pipe(map((r) => new EndorsementResponse(r)));
  };

  /**
   * upload letter head
   * @param template template
   * @param file letter head file
   * @returns  observable of template
   */
  uploadLetterHead(template, file) {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    formData.append('file', file, file.name);
    formData.append('template', template);
    return this.apiService.post(this.baseURL + '/templates/upload/', formData, {
      options: {
        headers: headers,
      },
    });
  }
}
