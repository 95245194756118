<modal-comp (mouseLeft)="close()" [isFullscreen]="true" [width]="width" [height]="height">
  <ng-template modalBody>
    <loader [loading]="loading()" class="vh-10" text="">
      @if (user(); as user) {
        <div class="row row-cols-lg-auto g-2 my-0 align-items-center ">
          @if (user.imageUrl) {
            <div>
              <img [src]="user.imageUrl" alt="User Profile Picture Preview" />
            </div>
          }
          @for (item of labels(); track item.label) {
            <div>
              <text-case-2
                [label]="item.label"
                [value]="item.value"
                [isEmail]="item.isEmail"
                [formatter]="item.formatter" />
            </div>
          }
        </div>
      } @else {
        <div class="">User not found</div>
      }
    </loader>
  </ng-template>
</modal-comp>
