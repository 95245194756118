import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { ITranslationGlossary } from 'projects/evolutics-client-ui/src/app/Life/Setup/translation/translation-extras/translation-glossary.request.model';
export namespace TranslationGlossaryStore {
  //#region STORE
  export interface IState {
    // glossary?: ITranslationGlossary;
  }

  const initialState: IState = {};
  //#endregion

  //#region ACTIONS
  export const actions = createActionGroup({
    source: 'Translation Glossary API',
    events: {
      // getFromLocal: emptyProps(),
      getFromOnline: emptyProps(),
      getFromOnlineSuccess: emptyProps(),
      // setGlossary: props<{ item: ITranslationGlossary }>(),
    },
  });
  //#endregion

  //#region SELECTORS
  export namespace selectors {
    // const select = (state: IAppState) => state.translationGlossary;
    // export const translationGlossary = createSelector(select, (state) => state.glossary);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    // on(actions.setGlossary, (state, { item }) => ({ ...state, glossary: item })),
  );
  //#endregion
}
