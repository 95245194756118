import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ICodeDescription } from '@Shared/models/index.model';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from '@Services/api.service';
import { ICompany } from 'projects/evolutics-client-ui/src/app/Life/Setup/organization/companies/create-company/create-company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  baseURL = environment.apiBaseUrl + '/rest';
  retryCount = 1;
  noRetry = 0;

  constructor(public apiService: ApiService) {}

  getCodeAndCompanyCodeTeirCat(tierCat: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/tier/code/companycode/${tierCat}`)
      .pipe(retry(this.retryCount));
  }

  getPaymentMethods(companyCode: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/payment/outward/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  getPaymentMethodsByUserCode(userCode: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/payout/methods/${userCode}`)
      .pipe(retry(this.retryCount));
  }

  deleteTierByID = (id: number[]) =>
    id?.length ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/tiers', id) : of(null);

  deleteEmailGatewayOutByID = (id: number) =>
    id
      ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + `/company/email/gateway/out/${id}`)
      : of(null);

  deleteToleranceByID = (id: number) =>
    id ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/tolerance/' + id) : of(null);

  deleteSuspiciousByID = (id: number) =>
    id ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/suspicious/' + id) : of(null);

  deleteUserMailGroupByID = (id: number) =>
    id
      ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/mail/user-group' + id)
      : of(null);

  getCompanyCodeByCompanyCode(companyCode: string) {
    if (!companyCode) return of<ICompany>(null);
    return this.apiService
      .get<ICompany>(`${this.baseURL}/company/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  getCompanyCodeAndDesc = () => {
    return this.apiService.getCodes<ICodeDescription>(`${this.baseURL}/company/code/desc`);
  };

  getCompanyMailGroup(company) {
    return this.apiService
      .get(`${this.baseURL}/company/mail/user-group/search?companyCode=${company}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyMailGroup(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/mail/user-group`, payload)
      .pipe(retry(this.retryCount));
  }

  updateCompanyMailGroup(payload) {
    return this.apiService
      .put(`${this.baseURL}/company/mail/user-group`, payload)
      .pipe(retry(this.retryCount));
  }

  getCompanyLossTrigger(company) {
    return this.apiService
      .get(`${this.baseURL}/company/loss/trigger/search?companyCode=${company}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyLossTrigger(payload) {
    return this.apiService.post(`${this.baseURL}/company/loss/trigger`, payload).pipe(retry(this.retryCount));
  }

  updateCompanyLossTrigger(payload) {
    return this.apiService.put(`${this.baseURL}/company/loss/trigger`, payload).pipe(retry(this.retryCount));
  }

  getCompanyDefaultWorkflows(company) {
    return this.apiService
      .get(`${this.baseURL}/company/tasks/search?companyCode=${company}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyDefaultWorkflows(payload) {
    return this.apiService.post(`${this.baseURL}/company/tasks`, payload).pipe(retry(this.retryCount));
  }

  updateCompanyDefaultWorkflows(id, payload) {
    return this.apiService.put(`${this.baseURL}/company/tasks/${id}`, payload).pipe(retry(this.retryCount));
  }

  getBranchCodeAndDescByCompanyCode = (companyCode: string) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `/branch/code/desc/${companyCode}`);
  };
  /**
   * get all branch codes with description
   * @returns observable
   */
  getBranchCodeAndDesc = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `/branch/code/desc`);
  };

  /**
   * get all branch code with description
   * @returns observable
   */
  getBranchDescByCode = (branchCode, companyCode) => {
    return branchCode && companyCode
      ? this.apiService.get<ICodeDescription>(this.baseURL + `/branch/${branchCode}/${companyCode}`)
      : of(null);
  };

  getCompanyidAndSenderEmailByCompanyCode(companyCode: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/id/sender/email/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  getCompanyidAndSenderEmail(): Observable<any> {
    return this.apiService.get(`${this.baseURL}/company/id/sender/email`).pipe(retry(this.retryCount));
  }

  // deleteEmployee(id: number): Observable<any> {
  //     return this.http.delete(`${this.URL}/${id}`, { responseType: 'text' });
  // }

  // update(id: string, employee: any): Observable<employee> {
  //     return this.http.put<employee>(`${this.URL}` + id, employee, this.httpOptions);
  // }

  // find(id: string): Observable<employee> {
  //     return this.http.get<employee>(`${this.URL}` + id);
  // }

  // Error handling
  handleError(error: { error: { message: string }; status: any; message: any }) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);

    return throwError(errorMessage);
  }
}
